import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/unittestbot.github.io/unittestbot.github.io/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "plugin-description"
    }}>{`Plugin description`}</h1>
    <h2 {...{
      "id": "ui-overview"
    }}>{`UI overview`}</h2>
    <p>{`Main UI elements of the plugin are:`}</p>
    <ul>
      <li parentName="ul">{`Status bar icons, they show connection status and verbose mode option`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`UTBot consoles`}</inlineCode>{`, a window to show logs from server (GTest log, Server log) and plugin (Client log)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`UTBot targets`}</inlineCode>{`, a window to show targets found by server in current project`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`UTBot Source Directories`}</inlineCode>{`, a view to show source directories marked green`}</li>
    </ul>
    <p><img alt="clionPluginOverview" src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/images/clion_plugin/overview.png?raw=true" /></p>
    <h2 {...{
      "id": "tests-generation"
    }}>{`Tests generation`}</h2>
    <p>{`You can trigger tests generation in multiple ways:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`From editor context menu. For example, right click in editor and choose `}<inlineCode parentName="p">{`UTBot Generate Tests... for File`}</inlineCode></p>
        <img src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/images/clion_plugin/generate/from-editor.png?raw=true" alt="fromEditor" height="400" width="500"></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`From project view context menu. For example, right click on file in project view and choose:
`}<inlineCode parentName="p">{`UTBot: Generate Tests For Folder`}</inlineCode></p>
  <img src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/images/clion_plugin/generate/from-project-view.png?raw=true" alt="fromProjectView" height="600"></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`From search window. Open search window by pressing `}<inlineCode parentName="p">{`Shift`}</inlineCode>{` twice. Then search for a particular
action, for instance `}<inlineCode parentName="p">{`UTBot: Generate For project`}</inlineCode></p>
      </li>
    </ul>
    <p><img alt="fromProjectView" src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/images/clion_plugin/generate/trigger-from-search.png?raw=true" /></p>
    <h2 {...{
      "id": "running-tests-with-coverage"
    }}>{`Running tests with coverage`}</h2>
    <h3 {...{
      "id": "run-a-single-test"
    }}>{`Run a single test`}</h3>
    <p><strong parentName="p">{`How-to steps`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`Open file with generated tests`}</li>
      <li parentName="ol">{`Click on the icon located near the test`}</li>
    </ol>
    <p><img alt="runSingleTestGif" src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/gifs/clion_plugin/run/run-single-test.gif?raw=true" /></p>
    <h3 {...{
      "id": "run-all-tests-in-a-file"
    }}>{`Run all tests in a file`}</h3>
    <p><strong parentName="p">{`How-to steps`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`Open file with generated tests`}</li>
      <li parentName="ol">{`Click on the icon located near `}<inlineCode parentName="li">{`UTBot`}</inlineCode>{` namespace`}</li>
    </ol>
    <p><img alt="runAllInFileGif" src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/gifs/clion_plugin/run/run-all-in-file.gif?raw=true" /></p>
    <h3 {...{
      "id": "run-all-tests-in-project"
    }}>{`Run all tests in project`}</h3>
    <p><strong parentName="p">{`How-to steps`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`Open search window by pressing `}<inlineCode parentName="li">{`Shift`}</inlineCode>{` twice`}</li>
      <li parentName="ol">{`Search for `}<inlineCode parentName="li">{`UTBot: Run All Tests and Show Coverage`}</inlineCode></li>
    </ol>
    <h2 {...{
      "id": "manage-coverage"
    }}>{`Manage coverage`}</h2>
    <p>{`Consider the following scenario: you ran tests `}<inlineCode parentName="p">{`A`}</inlineCode>{` with coverage, and then you run
some other tests `}<inlineCode parentName="p">{`B`}</inlineCode>{` with coverage. Then a dialog with three options will be opened: `}</p>
    <p><img alt="dialog" src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/images/clion_plugin/coverage/dialog.png?raw=true" /></p>
    <p>{`It is because there is already coverage displayed
from tests `}<inlineCode parentName="p">{`A`}</inlineCode>{`, and you can choose:`}</p>
    <ul>
      <li parentName="ul">{`to merge coverage from `}<inlineCode parentName="li">{`A`}</inlineCode>{` with `}<inlineCode parentName="li">{`B`}</inlineCode>{` `}<strong parentName="li">{`(Add to active suites)`}</strong></li>
      <li parentName="ul">{`to replace coverage from `}<inlineCode parentName="li">{`A`}</inlineCode>{` with coverage from `}<inlineCode parentName="li">{`B`}</inlineCode>{` `}<strong parentName="li">{`(Replace active suites)`}</strong></li>
      <li parentName="ul">{`to show coverge only from `}<inlineCode parentName="li">{`A`}</inlineCode>{` `}<strong parentName="li">{`(Do not apply collected coverage)`}</strong></li>
    </ul>
    <h2 {...{
      "id": "plugin-settings"
    }}>{`Plugin settings`}</h2>
    <p>{`You can view and edit available settings in `}<inlineCode parentName="p">{`Settings`}</inlineCode>{` -> `}<inlineCode parentName="p">{`Tools`}</inlineCode>{` -> `}<inlineCode parentName="p">{`UTBot Settings`}</inlineCode></p>
    <p><img alt="clionPluginSettingsDemo" src="https://github.com/UnitTestBot/unittestbot.github.io/blob/source/resources/gifs/clion_plugin/settings-demo.gif?raw=true" /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      